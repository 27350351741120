import React, { useState } from "react";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import "../styles/About.css";

const About = () => {

    const Images = [
        "../pic1.png",
        "../pic2.png",
        "../pic3.png"
    ];

    const [current, setCurrent] = useState(0);
    const length = Images.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    // const ImageSlider = () => {
    //     return (
    //         <section className='slider'>
    //             <FaArrowAltCircleLeft className='left-arrow' onClick={prevSlide} />
    //             <FaArrowAltCircleRight className='right-arrow' onClick={nextSlide} />
    //             {Images.map((image, index) => {
    //                 return (
    //                     <div className={index === current ? 'slide active' : 'slide'} key={index}>
    //                         {index === current && (
    //                             <img src={image} alt='travel image' className='image' />
    //                         )}
    //                     </div>
    //                 );
    //             })}
    //         </section>
    //     );
    // };

    const ImageExpand = () => {
        return (
            <div className="flex img-container">
                {Images.map((image) => {
                    return (
                        <img src={image} alt='profile pic' className="img-box" />
                    );
                })}
            </div>
        );
    };

    return (
        <section id="about" className="sm:px-16 px-6 sm:py-16 py-10 max-w-8xl mx-auto relative z-0`">
            <div className="component-container flex md:flex-row flex-col items-center">
                <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                    <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
                        Hi! I'm Ernest
                    </h1>
                    <h1 className="title-font mb-4 font-medium text-white">
                        Tech Lead | Software Developer | Cloud Architect
                    </h1>
                    <p className="mb-8 leading-relaxed text-justify">
                        Highly motivated to embrace new opportunities and thrive on challenging tasks. My expertise lies in troubleshooting and creative problem-solving, ensuring that any assigned work is executed with precision. I have ample experience in handling computer hardware and software with proficiency.
                    </p>
                    <div className="flex justify-center">
                        <a
                            href="#contact"
                            className="inline-flex text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg">
                            Work With Me
                        </a>
                        <a
                            href="#projects"
                            className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">
                            See My Past Work
                        </a>
                    </div>
                </div>
                <ImageExpand />
            </div>
        </section>
    );
};

export default About;