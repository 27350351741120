import { singtel, realtek, mmu, celcomdigi } from "../assets";

export const navLinks = [
    {
        id: "about",
        title: "About",
    },
    {
        id: "projects",
        title: "Projects",
    },
    {
        id: "experience",
        title: "Experience",
    },
    {
        id: "skills",
        title: "Skills",
    },
    {
        id: "contact",
        title: "Contact",
    },
];


export const experiences = [
    {
        title: "Tech Lead",
        company_name: "CelcomDigi",
        icon: celcomdigi,
        iconBg: "#383E56",
        date: "April 2022 - Present",
        points: [
            "Developed and maintained mobile and web applications using Java, React Native, and NodeJs.",
            "Collaborated effectively within an agile development environment to deliver high-quality software solutions.",
            "Implemented seamless integration of an on-premise system with the AWS cloud by using a serverless and scalable approach.",
            "Lead a team of 8 developers in the design, development, and implementation of a complex in-house application.",
            "Managed project timelines, resources, and budgets, consistently delivering projects on time and within budget.",
            "Conducted regular code reviews and provided constructive feedback to ensure code quality, performance, and adherence to best practices.",
        ],
    },
    {
        title: "SoC Software Enginerr",
        company_name: "Realtek Malaysia",
        icon: realtek,
        iconBg: "#E6DEDD",
        date: "July 2021 - Apr 2022",
        points: [
            "Developed broadcasting application for Android TV using TV Input Framework",
            "Studied broadcasting standards and shared findings with peers to optimize and enhance applications.",
            "Collaborated with hardware engineers to integrate device drivers and support for new hardware components into the AOSP codebase.",
            "Implemented middleware in Android applications according to NTSC Standards",
            "Assisted in the debugging and resolution of complex technical issues, providing timely solutions.",
        ],
    },
    {
        title: "Full Stack Developer",
        company_name: "Multimedia University Cyberjaya",
        icon: mmu,
        iconBg: "#383E56",
        date: "March 2021 - June 2021",
        points: [
            "Developed web applications using PHP and and maintain Apache XAMPP Server.",
            "Collaborated with engineer and researcher to fine-tune and develop high-quality prototype.",
            "Maintained database and generated reports for data analysis.",
            "Designed website based on requirement and research papers.",
        ],
    },
    {
        title: "Research Assistant",
        company_name: "NUS-Singtel Cyber Security R&D Laboratory",
        icon: singtel,
        iconBg: "#E6DEDD",
        date: "March 2020 - July 2020",
        points: [
            "Developed a secured face recognition authentication system.",
            "Collaborated with researchers and engineers to develop cutting-edge technology.",
            "Implemented security and cryptographic protocols using C++, Python and Java.",
            "Performed native integration between mobile application, AI and OpenSSL.",
            "Developed scripts to crawl test data to train AI model."
        ],
    },
];