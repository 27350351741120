export const projects = [
  {
    title: "Pacman",
    subtitle: "Object Oriented C++",
    description:
      "Low level C++ game clone that utilizes A star search algorithm, object oriented programming and collision detection.",
    image: "./pacman.gif",
    link: "https://github.com/ernestyyy0306/PacmanCpp",
  },
  {
    title: "Particle Detection Distance Calculator",
    subtitle: "Python Image Processing",
    description:
      "To detect number of particles in a picture, measure the distance between each of the particle, and record all the data.",
    image: "./particleDetection.gif",
    link: "https://github.com/ernestyyy0306/ParticleDetectPython",
  },
  {
    title: "Sales System GUI",
    subtitle: "Java & Swing",
    description:
      "A fastfood sales ordering system using Java Swing, using a variety of design patterns.",
    image: "./SalesSystem.gif",
    link: "https://github.com/ernestyyy0306/SaleSystemJava",
  },
  {
    title: "Restaurant Menu Web App",
    subtitle: "Angular Javascript",
    description:
      "A restaurant menu web application allowing customer to scroll freely through the menu, mainly for food delivery purposes.",
    image: "./yogiMenu.gif",
    link: "https://yogicafekkb.netlify.app",
  },
  {
    title: "Restaurant POS System",
    subtitle: "Angular Typescript",
    description:
      "Created in a day to replace an emergency outage of the orignal POS system, to avoid manual handwritten receipt and order tracking during POS outage.",
    image: "./yogiPos.gif",
    link: "https://github.com/ernestyyy0306/POS",
  },
];

export const skills = [
  "React",
  "Next Js",
  "Node Js",
  "MySQL",
  "Oracle SQL",
  "Android Native Java",
  "Java SpringBoot",
  "Python",
  "Tensorflow",
  "Angular Ts",
  "C++",
  "AWS"
];
