// src/App.js

import React from "react";
import { About, Contact, Navbar, Projects, Skills, Experience } from "./components";
import { BrowserRouter } from "react-router-dom";

export default function App() {
  return (
    <BrowserRouter>
      <div className="text-gray-400 bg-gray-900 body-font">
        <div className='bg-hero-pattern bg-cover bg-no-repeat bg-center'>
          <Navbar />
        </div>
        <About />
        <Projects />
        <Experience />
        <Skills />
        <Contact />
      </div>
    </BrowserRouter>
  );
}