import { BadgeCheckIcon, ChipIcon } from "@heroicons/react/solid";
import React from "react";
import { skills } from "../data";
import { motion } from "framer-motion";
import { styles } from "../styles/SectionWrapper";
import { textVariant } from "../utils/motion";
import { SectionWrapper } from "../hoc";

const Skills = () => {
    return (
        <section id="skills">
            <div className="container px-5 py-10 mx-auto">
                <div className="text-center mb-20">
                    <motion.div variants={textVariant()}>
                        <ChipIcon className="w-10 inline-block mb-4" />
                        <p className={`${styles.sectionSubText} text-center`}>
                            Tech Stack and Developer Tools
                        </p>
                        <h2 className={`${styles.sectionHeadText} text-center`}>
                            Skills
                        </h2>
                    </motion.div>
                </div>
                <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                    {skills.map((skill) => (
                        <div key={skill} className="p-2 sm:w-1/2 w-full">
                            <div className="bg-gray-800 rounded flex p-4 h-full items-center">
                                <BadgeCheckIcon className="text-green-400 w-6 h-6 flex-shrink-0 mr-4" />
                                <span className="title-font font-medium text-white">
                                    {skill}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default SectionWrapper(Skills, "skills");